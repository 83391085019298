<template>
  <div class="popper text-left">
    <div class="pt-2 pb-1 px-2">
      <h5 class="text-xs leading-normal mb-4">{{ titleText }}</h5>
      <div v-if="cardImage" class="mb-2">
        <div class="text-xs mb-1">{{ subTextOne }}</div>
        <img :src="cardImage" :alt="state" height="160" />
      </div>
      <div v-if="secondCardImage" class="mt-2 mb-2">
        <div class="text-xs mb-1">{{ subTextTwo }}</div>
        <img :src="secondCardImage" :alt="state" height="160" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardImage: "",
      secondCardImage: "",
      titleText: "",
      subTextOne: "",
      subTextTwo: "",
    }
  },
  props: {
    state: {
      type: String,
    },
  },
  watch: {
    state(value) {
      this.getTooltipData(value);
    },
  },
  methods: {
    getTooltipData(value) {
      this.secondCardImage = "";
      this.subTextOne = "";
      this.subTextTwo = "";
      switch (value) {
        case "ACT":
          this.titleText = "ACT - located on front of licence";
          this.cardImage = require("@/assets/images/licenseCards/ACT.png");
          break;

        case "NSW":
          this.titleText = "NSW - located on front of licence";
          this.cardImage = require("@/assets/images/licenseCards/NSW.png");
          break;

        case "NT":
          this.titleText = "NT - located on back of licence";
          this.cardImage = require("@/assets/images/licenseCards/NT-After.png");
          this.secondCardImage = require("@/assets/images/licenseCards/NT-Before.png");
          this.subTextOne = "After 1 Nov 2020";
          this.subTextTwo = "Before 1 Nov 2020 ";
          break;

        case "QLD":
          this.titleText = "QLD - located on front and/or back of licence";
          this.cardImage = require("@/assets/images/licenseCards/QLD-Front.png");
          this.secondCardImage = require("@/assets/images/licenseCards/QLD-Back.png");
          this.subTextOne = "Front";
          this.subTextTwo = "Back";
          break;

        case "SA":
          this.titleText = "SA - located on back of licence";
          this.cardImage = require("@/assets/images/licenseCards/SA.png");
          break;

        case "TAS":
          this.titleText = "TAS - located on back of licence";
          this.cardImage = require("@/assets/images/licenseCards/TAS.png");
          break;

        case "VIC":
          this.titleText = "VIC - located on back of licence";
          this.cardImage = require("@/assets/images/licenseCards/VIC.png");
          break;

        case "WA":
          this.titleText = "WA - located on back of licence";
          this.cardImage = require("@/assets/images/licenseCards/WA.png");
          break;
      }
    }
  },
  mounted() {
    this.getTooltipData(this.state);
  }
};
</script>

<style>
.popper{
  max-width: 272px;
  left: 4px !important;
  margin-bottom: 8px!important;
  border-radius: 12px!important;
  background: #F8F8F8!important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25)!important;
}
.popper[x-placement^="top"]{
  margin-bottom: 8px!important;
}
.popper[x-placement^="bottom"]{
  margin-top: 8px!important;
}
.popper[x-placement^="top"] .popper__arrow{
  border-width: 8px 8px 0 8px!important;
  border-color: #F8F8F8 transparent transparent transparent!important;
  bottom: -8px!important;
}
.popper[x-placement^="bottom"] .popper__arrow{
  border-width: 0 8px 8px 8px!important;
  border-color: transparent transparent #F8F8F8 transparent!important;
  top: -8px!important;
}
</style>
